.logo.logo-animation {
    @keyframes opacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes scale {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.5);
        }
    }

    & > * {
        animation: opacity 1s ease-in-out infinite alternate both;
        
        & > * {
            animation-delay: inherit !important;
            animation: scale 1s ease-in-out infinite alternate both;
            transform-origin: center;
            transform-box: fill-box;
        }
    }
}
