@font-face {
    font-family: 'Arial';
    src: url('../fonts/arial.woff2') format('woff2'),
         url('../fonts/arial.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('../fonts/arial-bold.woff2') format('woff2'),
         url('../fonts/arial-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}