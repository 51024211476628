.fly-animation{
    animation: fly 3s infinite ease-in-out alternate both;
}

@keyframes fly {
    0%{
        transform: translateY(-10%);
    }
    100% {
        transform: none;
    }
}