.TextWithMedia {
    .item {
        &:not(:nth-last-of-type(1)) {
            @apply tw-mb-20;
        }

        &:nth-of-type(even) {
            & > *:nth-of-type(2) {
                @apply tw-row-start-1;
            }
        }
    }
}
