@tailwind base;
@tailwind components;
@tailwind utilities;

@import "import-fonts";
@import "plyr";
@import "form";
@import "svg";
@import "typgraphy";
@import "base";
@import "components";
@import "lists";
@import "logo-animation";
@import "effects";
