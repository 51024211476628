.Text {
    .content{
        ul {
            @apply tw-font-bold;

            li{
                list-style-type: none;

                @apply tw-flex tw-items-center;

                &::before{
                    content: "";
                    
                    @apply tw-w-4 tw-h-2 tw-mr-2 tw-border-l-2 tw-border-b-2 tw-origin-center -tw-rotate-45 -tw-translate-y-0.5 tw-border-brand-500;
                }
            }
        }
    }
}
