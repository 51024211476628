.Header{
    #toggle-menu{
        #open{
            @apply tw-block;
        }
        
        #close{
            @apply tw-hidden;
        }

        &.open{
            #open{
                @apply tw-hidden;
            }

            #close{
                @apply tw-block;
            }
        }
    }

    nav{
        @apply tw-translate-x-full tw-transition-transform tw-duration-500 tw-ease-in-out;
        
        height: calc(100vh - 100%);
        
        &.open{
            @apply tw-translate-x-0;
        }

    }
}