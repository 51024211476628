*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: theme("fontFamily.arial");
    scroll-behavior: smooth;
}

body {
    @apply tw-overflow-x-hidden;
}