@import "~plyr/dist/plyr.css";

.plyr {
    &.plyr--video {
        &.plyr__poster-enabled {
            .plyr__poster {
                background-position: top;
                background-size: cover;
            }
            .plyr__control--overlaid {
                @apply tw-bg-brand-500;
                &:hover {
                    @apply tw-bg-brand-500;
                }
            }
        }
    }
    &.plyr--full-ui input[type=range] {
        color: theme("colors.brand.500");
    }

    .plyr__control--overlaid {
        background: rgba(210, 20, 25, .8);
    }

    .plyr__control.plyr__tab-focus,
    .plyr__control:hover,
    .plyr__control[aria-expanded=true] {
        @apply tw-bg-brand-500;
    }

    .plyr__control.plyr__tab-focus {
        box-shadow: 0 0 0 5px rgba(210, 20, 25, .5);
    }

    .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
        @apply tw-bg-brand-500;
    }
}